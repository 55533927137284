import React from "react"
import { TheLayout } from "x"

const NotFoundPage = () => (
  <TheLayout seoTitle="404: Not found">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </TheLayout>
)

export default NotFoundPage
